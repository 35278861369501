<template>
  <a-layout class="index animated fadeIn">
    <a-card>
      <a-form ref='form' :model='search' :style="{'marginBottom':'20px'}" :initialValues="search" :onFinish='pageSearchChange' layout='inline'>
        <!-- <a-form-item label="应用名称" name='keywords'>
          <a-input v-model:value="search.keywords" :style="{width:'250px'}" placeholder="请输入应用名称"></a-input>
        </a-form-item> -->
        <a-form-item label="品牌商" name='brand'>
          <a-input v-model:value="search.brand" :style="{width:'250px'}" placeholder="请输入品牌商编号/名称/账号"></a-input>
        </a-form-item>
        <a-form-item label="销售类型" name='level_id'>
          <a-select v-model:value="search.sale_type" :style="{width:'180px'}" placeholder="全部">
            <a-select-option value="">
              全部
            </a-select-option>
            <a-select-option value="1">
              开户
            </a-select-option>
            <a-select-option value="2">
              续费
            </a-select-option>
            <a-select-option value="3">
              开通应用
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="收费类型" name='level_id'>
          <a-select v-model:value="search.charge_type" :style="{width:'180px'}" placeholder="全部">
            <a-select-option value="">
              全部
            </a-select-option>
            <a-select-option value="1">
              平台全包
            </a-select-option>
            <a-select-option value="2">
              模块收费
            </a-select-option>
            <a-select-option value="3">
              全包续费
            </a-select-option>
            <a-select-option value="4">
              模块续费
            </a-select-option>
            <a-select-option value="5">
              开通新应用
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="销售时间" name='time'>
          <a-range-picker v-model:value="time" valueFormat='YYYY-MM-DD' format='YYYY-MM-DD' @change="onChange" />
        </a-form-item>
        <!-- <a-form-item label="应用状态" name='time_type'>
          <a-select v-model:value="search.time_type" :style="{width:'180px'}" placeholder="全部">
            <a-select-option value="">
              全部
            </a-select-option>
            <a-select-option value="1">
              正常
            </a-select-option>
            <a-select-option value="2">
              续费期
            </a-select-option>
            <a-select-option value="3">
              已过期
            </a-select-option>
          </a-select>
        </a-form-item> -->
        <a-form-item>
          <a-button type="primary" htmlType='submit'>搜索</a-button>
        </a-form-item>
      </a-form>
      <a-table :pagination="false" :loading="listLoading" bordered rowKey="id" :columns="columns" :data-source="data">
        <!-- <template v-slot:action="{ record }">
          <a-dropdown>
            <a class="ant-dropdown-link"> 操作
              <DownOutlined />
            </a>
            <template v-slot:overlay>
              <a-menu>
                <a-menu-item>
                  <a v-has:productLogDetail="()=> productLogDetail(record)" href="javascript:;">详情</a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </template> -->
        <!-- <template v-slot:action="{ record }">
          <a @click="productLogSee(record)" href="javascript:;">查看</a>
        </template> -->
      </a-table>
      <a-pagination :style="{ marginTop: '20px', textAlign: 'right' }" show-size-changer show-quick-jumper :current="search.page" :page-size="search.limit" :total="total" @change="pageCurrentChange" @showSizeChange="pageSizeChange" :show-total="total => `总数: ${total} `" />
    </a-card>
  </a-layout>
  <a-modal title="产品使用记录" v-model:visible="visible" :width="1000">
    <p class="m-b-10">模块名称:{{app_name}}</p>
    <a-table :pagination="false" :loading="detailLoading" bordered rowKey="id" :columns="detailColumns" :data-source="detailData" :scroll="{ x: true }">
    </a-table>
  </a-modal>
  <!-- <a-modal title="关联应用" v-model:visible="relationvisible" :width="500" @ok="relationvisible=false">
    <p class="m-b-10">销售类型:{{app_name}}</p>
    <p class="m-b-10">收费类型:{{app_name}}</p>
    <p class="m-b-10">关联应用:{{app_name}}</p>
  </a-modal> -->
</template>
<script>
import { DownOutlined } from "@ant-design/icons-vue";
import { ref, onMounted, reactive, toRefs } from "vue";
import { $iscode } from "@/utils/app";
import { brandAppsLogsList, brandAppsLogsDetail } from "@/api/product";
// 初始化默认筛选项数值
let defSearch = {
  page: 1,
  limit: 10,
  time_type: "",
  fees_type: "",
  keywords: "",
  brand: "",
  start_time:"",
  end_time:"",
  charge_type:"",
  sale_type:"",
};
export default {
  components: { 
    // DownOutlined 
    },
  setup(props) {
    // // 初始化 分页信息和筛选项信息
    let search = ref({ ...defSearch });
    // 列表数据和列头配置
    let data = ref([]);
    // 详情数据存放

    let detailData = ref([]);
    const state = reactive({
      listLoading: false,
      visible: false,
      // relationvisible: false,
      total: 1,
      detailLoading: false,
      app_name: "",
      time: [],
    });
    let columns = [
      {
        title: "销售类型",
        dataIndex: "sale_type_text",
        key: "sale_type_text",
      },
      {
        title: "收费类型",
        dataIndex: "charge_type_text",
        key: "charge_type_text",
      },
      // {
      //   title: "应用名称",
      //   dataIndex: "brand_name",
      //   key: "brand_name",
      // },
      {
        title: "品牌商名称",
        dataIndex: "company",
        key: "company",
      },
      {
        title: "品牌商账号",
        dataIndex: "account",
        key: "account",
      },
      // {
      //   title: "收费方式",
      //   dataIndex: "fees_type_text",
      //   key: "fees_type_text",
      // },
      // {
      //   title: "有效期",
      //   dataIndex: "expire_time_text",
      //   key: "expire_time_text",
      // },
      // {
      //   title: "状态",
      //   dataIndex: "period_text",
      //   key: "period_text",
      // },
      {
        title: "价格(元)",
        dataIndex: "show_price",
        key: "show_price",
      },
      {
        title: "销售时间",
        dataIndex: "create_time",
        key: "create_time",
      },
      // {
      //   title: "操作",
      //   key: "action",
      //   width: 120,
      //   slots: { customRender: "action" },
      // },
      // {
      //   title: "关联应用",
      //   key: "action",
      //   width: 120,
      //   slots: { customRender: "action" },
      // },
    ];
    let detailColumns = [
      {
        title: "付费类型",
        dataIndex: "fees_type",
        key: "fees_type",
        customRender: ({ record }) => {
          return record.fees_type == 1 ? "年限收费" : "服务收费";
        },
      },
      {
        title: "原品牌商价格",
        dataIndex: "show_price_before",
        key: "show_price_before",
      },
      {
        title: "变动后品牌商价格",
        dataIndex: "show_price_after",
        key: "show_price_after",
      },
      {
        title: "原代理商价格",
        dataIndex: "price_before",
        key: "price_before",
      },
      {
        title: "变动后代理商价格",
        dataIndex: "price_after",
        key: "price_after",
      },
      {
        title: "原过期时间",
        dataIndex: "expire_time_before_text",
        key: "expire_time_before_text",
      },
      {
        title: "最新过期时间",
        dataIndex: "expire_time_after_text",
        key: "expire_time_after_text",
      },
      {
        title: "修改时间",
        dataIndex: "update_time",
        key: "update_time",
      },
      {
        title: "备注",
        dataIndex: "remarks",
        key: "remarks",
        fixed: "right",
      },
    ];
    // 页面筛选项搜索
    const pageSearchChange = () => {
      search.value = { ...search.value, page: 1 };
      initData(search.value);
    };
    // 页面筛选项重置
    const pageSearchReset = () => {
      search.value = {
        ...defSearch,
        page: search.value.page,
        size: search.value.limit,
      };
    };
    // 分页当前页切换
    const pageCurrentChange = (page, pageSize) => {
      search.value.page = page;
      initData(search.value);
    };
    // 分页当前页显示多少条切换
    const pageSizeChange = (current, size) => {
      search.value.page = 1;
      search.value.limit = size;
      initData(search.value);
    };
    const initData = async (values) => {
      state.listLoading = true;
      data.value = [];
      let res = await brandAppsLogsList(values)
        .then((res) => res.data)
        .catch((error) => error);
      if ($iscode(res)) {
        data.value = res.data.data;
        state.total = res.data.total;
      }
      state.listLoading = false;
    };
    // 时间筛选
    const onChange = (e) => {
      if (e.length > 0) {
        search.value.start_time = e[0];
        search.value.end_time = e[1];
      } else {
        search.value.start_time = "";
        search.value.end_time = "";
      }
    };
    const productLogDetail = async (record) => {
      state.detailLoading = true;
      state.app_name = record.app_name;
      state.visible = true;
      let result = await brandAppsLogsDetail({ id: record.id })
        .then((res) => res.data)
        .catch((error) => error);
      state.detailLoading = false;
      if ($iscode(result, true)) {
        detailData.value = result.data;
      } else {
        state.visible = false;
      }
    };
    //查看详情
    // const productLogSee = async (record) => {
    //   state.app_name = record.app_name;
    //   state.relationvisible = true;
    //   let result = await brandAppsLogsDetail({ id: record.id })
    //     .then((res) => res.data)
    //     .catch((error) => error);
    //   state.detailLoading = false;
    //   if ($iscode(result, true)) {
    //     detailData.value = result.data;
    //   } else {
    //     state.relationvisible = false;
    //   }
    // };
    // const productLogSee = (e) => {
    //   state.relationvisible = true;
    // };
    onMounted(() => {
      initData(search.value);
    });
    return {
      ...toRefs(state),
      search,
      data,
      columns,
      pageSearchChange,
      pageSearchReset,
      pageCurrentChange,
      pageSizeChange,
      productLogDetail,
      detailColumns,
      detailData,
      onChange,
      // productLogSee,
    };
  },
};
</script>
